<template>
  <v-dialog persistent v-model="show" max-width="500">
    <v-card>
      <div class="pt-10">
        <v-img src="../../assets/images/mail-icon.png" class="dialog-icon" />
      </div>
      <div class="text-center text-h6 font-weight-black dialog-title">
        {{ $t('signUpContent.dialog_top') }}
      </div>
      <div class="text-center pb-8 pt-2 px-10 dialog-body">
        {{ $t('signUpContent.dialog_body') }}
      </div>
      <div class="text-center pb-8">
        <v-btn color="primary" class="dialog-button" @click="ok">
          {{ $t('content.ok') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuthDialog',
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-icon {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.dialog-title {
  font-size: 24px;
  color: #16325c;
}
.dialog-body {
  font-size: 15px;
  color: #16325ccc;
}
.dialog-button {
  width: 100px;
  text-transform: none;
}
</style>
