<template>
  <AuthBox :wideBg="true">
    <div class="py-15">
      <h1 class="auth-title indigo--text text--darken-4 mb-10">
        {{ $t('signUpContent.new_password_title') }}
      </h1>
      <form>
        <v-row>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.new_password')" :validator="$v.form.new_password">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.new_password')" />
                <v-text-field
                  v-model="form.new_password"
                  v-bind="attrs"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  class="rounded-lg"
                  hint="At least 8 characters"
                  counter
                  :placeholder="$t('form.enter_new_password')"
                  @input="$v.form.new_password.$touch()"
                  @blur="$v.form.new_password.$touch()"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.re_new_password_upr')" :validator="$v.form.confirm_new_password">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.re_new_password_upr')" />
                <v-text-field
                  v-model="form.confirm_new_password"
                  v-bind="attrs"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  outlined
                  dense
                  class="rounded-lg"
                  hint="At least 8 characters"
                  counter
                  :placeholder="$t('form.re_enter_new_password')"
                  @input="$v.form.confirm_new_password.$touch()"
                  @blur="$v.form.confirm_new_password.$touch()"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
      </form>
      <div class="pl-3 mt-10">
        <v-btn :disabled="!this.$v.form.$dirty" class="mr-1 indigo white--text no-transform" x-large @click="submit">
          {{ $t('form.submit') }}
        </v-btn>
      </div>
    </div>
    <template>
      <v-dialog persistent v-model="showAuthDialog" max-width="500">
        <v-card>
          <div class="pt-10">
            <v-img src="../../assets/images/success-icon.png" class="dialog-image" />
          </div>
          <div class="text-center indigo--text text--darken-4 text-h4">
            {{ $t('form.new_password_top') }}
          </div>
          <div class="text-center indigo--text text--darken-3 pt-3 pb-8">
            {{ $t('form.new_password_body') }}
          </div>
          <div class="text-center pb-8">
            <v-btn color="primary no-transform" @click="$router.push({ name: 'AuthLogin' })">
              {{ $t('signUpContent.to_login') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </template>
  </AuthBox>
</template>

<script>
import AuthBox from '@/components/auth/AuthBox';
import password from '@/validators/password';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'AuthResetPassword',
  components: { AuthBox },
  validations: {
    form: {
      new_password: { required, minLength: minLength(8), password },
      confirm_new_password: {
        required,
        sameAsPassword: sameAs('new_password'),
        password,
      },
    },
  },
  data() {
    return {
      form: {
        new_password: '',
        confirm_new_password: '',
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      },
      showPassword: false,
      showConfirmPassword: false,
      showAuthDialog: false,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.$store
          .dispatch('Auth/newPassword', this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.showAuthDialog = true;
          })
          .catch(err => this.$toast.error(err.message));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-image {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.no-transform {
  text-transform: none;
}
</style>
