<template>
  <AuthBox :wideBg="true">
    <div v-if="show == 'activation'" class="py-16">
      <h1 class="auth-title indigo--text text--darken-4 pl-5 mb-3">
        {{ $t('signUpContent.activate_title') }}
      </h1>
      <div class="pl-3 mt-10">
        <v-btn class="mr-1 indigo white--text no-transform" x-large @click="submit">
          {{ $t('form.activate') }}
        </v-btn>
      </div>
    </div>
    <div v-else class="py-16">
      <h1 class="auth-title indigo--text text--darken-4 pl-5 mb-3">
        {{ $t('account.resend') }}
      </h1>
      <form class="pl-5">
        <v-row>
          <v-col cols="12" class="pt-10 pb-1">
            <BaseFormGroup :name="$t('form.email')" :validator="$v.formResend.email">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.email')" />
                <v-text-field
                  v-model="formResend.email"
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg"
                  :placeholder="$t('form.enter_email')"
                  @input="$v.formResend.email.$touch()"
                  @blur="$v.formResend.email.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
      </form>
      <div class="pl-5 mt-10">
        <v-btn :disabled="!this.$v.formResend.$dirty" class="mr-1 indigo white--text no-transform" @click="submitResend">
          {{ $t('account.resend_link') }}
        </v-btn>
      </div>
    </div>
    <template>
      <v-dialog persistent v-model="showAuthDialog" max-width="400" content-class="elevation-0">
        <v-card>
          <div class="pt-5">
            <v-img src="../../assets/images/success-icon.png" class="dialog-image" />
          </div>
          <div class="text-center text-h5 font-weight-bold pt-3 pb-2 dialog-title">
            {{ $t('form.success') }}
          </div>
          <div class="text-center indigo--text text--darken-3 pb-8">
            {{ $t('form.activation_body') }}
          </div>
          <div class="text-center pb-8">
            <v-btn color="primary" @click="redirectToLogin" class="no-transform">
              {{ $t('signUpContent.to_login') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <AuthDialog v-model="showResendDialog" @ok="ok" />
      <ExpiredDialog ref="alert" />
    </template>
  </AuthBox>
</template>

<script>
import AuthBox from '@/components/auth/AuthBox';
import { email, required } from 'vuelidate/lib/validators';
import AuthDialog from '@/components/auth/AuthDialog.vue';
import ExpiredDialog from '@/components/base/BaseAlert';

export default {
  name: 'AuthEmailActivation',
  components: { AuthBox, AuthDialog, ExpiredDialog },
  data() {
    return {
      form: {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      },
      formResend: {
        email: '',
      },
      showAuthDialog: false,
      showResendDialog: false,
      showExpiredDialog: true,
      show: 'activation',
    };
  },
  validations: {
    formResend: {
      email: { email, required },
    },
  },
  methods: {
    submit() {
      this.$store
        .dispatch('Auth/activation', this.form)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showAuthDialog = true;
        })
        .catch(async err => {
          if (err == 'Error: Invalid token for given user.') {
            if (
              await this.$refs.alert.open({
                type: 'confirm',
                cancelBtn: false,
                title: `${this.$t('account.expired_activation')}`,
                message: `${this.$t('account.expired_redirect')}`,
                btnAgreeText: `${this.$t('content.ok')}`,
                icon: 'expired',
              })
            ) {
              this.show = 'resend';
            }
          } else if (err == 'Error: Stale token for given user.') {
            this.$toast.error('Account already activated');
          } else {
            this.show = 'resend';
            this.$toast.error(err.message);
          }
        });
    },
    submitResend() {
      this.$v.$touch();
      if (this.$v.formResend.$invalid) {
        return;
      } else {
        this.$store
          .dispatch('Auth/reactivation', this.formResend)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.showResendDialog = true;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.$toast.error('Invalid link or email is already activated');
          });
      }
    },
    ok() {
      this.showResendDialog = false;
    },
    redirectToLogin() {
      this.$router.push({ name: 'AuthLogin' });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}
.dialog-title {
  color: #16325c;
}
.dialog-image {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
</style>
